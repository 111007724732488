import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {FormControl, InputLabel,InputAdornment, Input} from '@mui/material';
import {VpnKey} from "@mui/icons-material"
import './App.css';

function App() {
  return (
    <div style={{margin: "auto", width: "50%", padding: "10px", textAlign: "center"}}>
      <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <h1>Demo mode:</h1>
          <FormControl variant="standard">
        <InputLabel htmlFor="input-with-icon-">
          Enter your demo Token
        </InputLabel>
        <Input
          id="input-with-icon-adornment"
          // startAdornment={
          //   <InputAdornment position="start">
          //     <VpnKey />
          //   </InputAdornment>
          // }
          endAdornment={
            <InputAdornment position="start">
            <VpnKey />
          </InputAdornment>
          }
        />
        
      </FormControl>
        </Grid>
      </Grid>
    </Box>  
    </div>
  );
}

export default App;
